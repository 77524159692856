<template>
    <div class="menu-page" v-if="currentUser?.admin">
        <h1>Dashboard</h1>

        <h2 class="mt-4">Base atual</h2>
        <v-row>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-4 pb-4" elevation="2">
                    <v-card-title>
                        <v-icon class="icon" color="success" left> mdi-account-multiple-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="real_time.active" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">Ativos</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-4 pb-4" elevation="2">
                    <v-card-title>
                        <v-icon class="icon" color="amber" left> mdi-alert-box-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="real_time.pastdue" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">Em atraso</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-4 pb-4" elevation="2">
                    <v-card-title>
                        <v-icon class="icon" color="error" left> mdi-close-circle-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">

                                    <animated-number :value="real_time.canceled" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">Cancelados</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <h2 class="mt-8">Novas vendas</h2>
        <v-row>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" elevation="2">
                    <v-card-title>
                        <v-icon class="icon" color="success" left> mdi-account-plus-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_status.today" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">Hoje</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" elevation="2">
                    <v-card-title>
                        <v-icon class="icon" color="success" left> mdi-account-plus-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_status.week" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">7 dias</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" elevation="2">
                    <v-card-title>
                        <v-icon class="icon" color="success" left> mdi-account-plus-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_status.month" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">30 dias</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        
        <h2 class="mt-8">Faturamento</h2>
        <v-row>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" elevation="2">
                    <v-card-title>
                        <div class="card-div">
                            <v-icon class="icon" color="success" left> mdi-currency-usd </v-icon>
                            <div class="text-subtitle-1">Hoje</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_income.today.new_clients + sell_income.today.old_clients" round="1" :formatValue="formatToPrice" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.today.new_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Novos
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.today.old_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Recorrências
                                </span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" elevation="2">
                    <v-card-title>
                        <div class="card-div">
                            <v-icon class="icon" color="success" left> mdi-currency-usd </v-icon>
                            <div class="text-subtitle-1">7 dias</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_income.week.new_clients + sell_income.week.old_clients" round="1" :formatValue="formatToPrice" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.week.new_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Novos
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.week.old_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Recorrências
                                </span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" elevation="2">
                    <v-card-title>
                        <div class="card-div">
                            <v-icon class="icon" color="success" left> mdi-currency-usd </v-icon>
                            <div class="text-subtitle-1">30 dias</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_income.month.new_clients + sell_income.month.old_clients" round="1" :formatValue="formatToPrice" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.month.new_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Novos
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.month.old_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Recorrências
                                </span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <h2 class="mt-8">Projeções</h2>
        <v-row>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" elevation="2">
                    <v-card-title>
                        <div class="card-div">
                            <v-icon class="icon" color="success" left> mdi-currency-usd </v-icon>
                            <div class="text-subtitle-1">Hoje</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_income.two_weeks.average" round="1" :formatValue="formatToPrice" :duration="600" />
                                    <v-chip v-if="sell_income.today.projection_diff" label small class="ml-2" :color="`${sell_income.today.projection_diff >= 0 ? 'success' : 'error'}`">{{ sell_income.today.projection_diff.toFixed(1) + '%' }}</v-chip>
                                </span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" elevation="2">
                    <v-card-title>
                        <div class="card-div">
                            <v-icon class="icon" color="success" left> mdi-currency-usd </v-icon>
                            <div class="text-subtitle-1">7 dias</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_income.two_weeks.average * 7" round="1" :formatValue="formatToPrice" :duration="600" />
                                    <v-chip v-if="sell_income.week.projection_diff" label small class="ml-2" :color="`${sell_income.week.projection_diff >= 0 ? 'success' : 'error'}`">{{ sell_income.week.projection_diff.toFixed(1) + '%' }}</v-chip>
                                </span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" elevation="2">
                    <v-card-title>
                        <div class="card-div">
                            <v-icon class="icon" color="success" left> mdi-currency-usd </v-icon>
                            <div class="text-subtitle-1">30 dias</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_income.two_weeks.average * 30" round="1" :formatValue="formatToPrice" :duration="600" />
                                    <v-chip v-if="sell_income.month.projection_diff" label small class="ml-2" :color="`${sell_income.month.projection_diff >= 0 ? 'success' : 'error'}`">{{ sell_income.month.projection_diff.toFixed(1) + '%' }}</v-chip>
                                </span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>


        <v-row class="mt-4">
            <v-col cols="12">
                <v-card :loading="loading">
                    <v-card-title>
                        Tipos de venda - 60 dias
                    </v-card-title>
                    <v-card-text :class="`${loading ? 'blur' : ''}`">
                        <div id="chart4">
                            <apexChart ref="chart4" height="450" :type="charts_type" :options="chart4Options" :series="series4"></apexChart>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col cols="12">
                <v-card :loading="loading">
                    <v-card-title>
                        Distribuição de ciclos - 30 dias
                    </v-card-title>
                    <v-card-text :class="`${loading ? 'blur' : ''}`">
                        <div id="chart4">
                            <apexChart ref="chart3" height="450" :type="charts_type" :options="chart3Options" :series="series3"></apexChart>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col cols="12">
                <v-card :loading="loading">
                    <v-card-title>
                        Faturamento diário - 60 dias
                    </v-card-title>
                    <v-card-text :class="`${loading ? 'blur' : ''}`">
                        <div id="chart4">
                            <apexChart ref="chart5" height="450" :type="charts_type" :options="chart5Options" :series="series5"></apexChart>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col cols="12">
                <v-card :loading="loading">
                    <v-card-title>
                        Receita média - 60 dias
                    </v-card-title>
                    <v-card-text :class="`${loading ? 'blur' : ''}`">
                        <div id="chart6">
                            <apexChart ref="chart6" height="450" :type="charts_type" :options="chart6Options" :series="series6"></apexChart>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col cols="12">
                <v-card :loading="loading">
                    <v-card-title>
                        Cancelamentos - 60 dias
                    </v-card-title>
                    <v-card-text :class="`${loading ? 'blur' : ''}`">
                        <div id="chart4">
                            <apexChart ref="chart1" height="450" :type="charts_type" :options="chart1Options" :series="series1"></apexChart>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import AnimatedNumber from "animated-number-vue";

export default {
    name: "DashboardView",

    components: {
        AnimatedNumber
    },

    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    data() {
        return {
            loading: false,
            real_time: { active: 0, pastdue: 0, canceled: 0 },
            sell_status: {
                today: 0,
                week: 0,
                month: 0,
            },
            sell_income: {
                today: { new_clients: 0, old_clients: 0, total: 0, projection_diff: 1 },
                week: { new_clients: 0, old_clients: 0, total: 0, projection_diff: 1 },
                month: { new_clients: 0, old_clients: 0, total: 0, projection_diff: 1 },
                two_weeks: { new_clients: 0, old_clients: 0, total: 0 },
            },
            series1: [],
            series2: [],
            series3: [],
            series4: [],
            series5: [],
            series6: [],
            charts_type: 'bar',

            chart1Options: {
                chart: {
                    stacked: true,
                    zoom: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            enabled: true,
                            total: {
                                enabled: true,
                                formatter: function (w) {
                                    return w;
                                },
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        },
                    }
                },
                dataLabels: {
                    enabled: true
                },
                colors: ['#e53935'],
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },

            chart3Options: {
                grid: { show: false },
                chart: {
                    zoom: {
                        enabled: true
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10
                    }
                },
                dataLabels: {
                    enabled: true
                },
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },

            chart4Options: {
                grid: { show: false },
                chart: {
                    stacked: true,
                    zoom: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            enabled: true,
                            total: {
                                enabled: true,
                                formatter: function (w) {
                                    return w;
                                },
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                },
                colors: ['#26e7a6', '#26a0fc'],
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },

            chart5Options: {
                grid: { show: false },
                chart: {
                    stacked: true,
                    zoom: {
                        enabled: true
                    }
                },
                colors: ['#4caf50'],
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            enabled: true,
                            total: {
                                enabled: true,
                                formatter: function (w) {
                                    if (w) {
                                        w = (w / 1000).toFixed(1)
                                    }
                                    return `${w || 0}k`;
                                },
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        },
                    }
                },
                dataLabels: {
                    enabled: false,

                },
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },

            chart6Options: {
                grid: { show: false },
                chart: {
                    stacked: true,
                    zoom: {
                        enabled: true
                    }
                },
                colors: ['#4caf50'],
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            enabled: true,
                            total: {
                                enabled: true,
                                formatter: function (w) {
                                    if (w) {
                                        w = (w / 1000).toFixed(1)
                                    }
                                    return `${w || 0}k`;
                                },
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        },
                    }
                },
                dataLabels: {
                    enabled: false,

                },
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },

        };
    },

    async mounted() {
        this.loading = true;
        const resp = await Api.getFinancialDashboardData();
        this.loading = false;

        if (resp && resp.error) {
            return emitToastr("error", resp.message)
        }

        if (resp && resp.message) {
            this.real_time = resp.message.real_time;

            setTimeout(() => {
                this.sell_status = resp.message.sell_status;
                this.sell_income = resp.message.sell_income;

                this.sell_income.two_weeks.average = this.sell_income.two_weeks.total / 14;

                this.sell_income.today.projection_diff =  100 -((this.sell_income.today.total / this.sell_income.two_weeks.average) * 100);

                this.sell_income.week.average = this.sell_income.week.total / 7;
                this.sell_income.week.projection_diff = 100 - ((this.sell_income.week.average / this.sell_income.two_weeks.average) * 100);

                this.sell_income.month.average = this.sell_income.month.total / 30;
                this.sell_income.month.projection_diff = 100 - ((this.sell_income.month.average / this.sell_income.two_weeks.average) * 100);
            }, 500);

            if (resp.message.canceled_chart) {
                let cat = [];
                let canceled_series = { name: "Cancelados", data: [] };

                resp.message.canceled_chart.forEach(i => {
                    cat.push(i._id);
                    canceled_series.data.push(i.canceled);
                });

                this.series1 = [canceled_series];
                this.chart1Options.xaxis.categories = cat;
                this.$refs.chart1.refresh();
            }

            if (resp.message.cycle_chart) {
                let cat = [];
                let cycle_series = { name: "Ciclo", data: [] };

                resp.message.cycle_chart.forEach(i => {
                    cat.push(i._id);
                    cycle_series.data.push(i.all);
                });

                this.series3 = [cycle_series];
                this.chart3Options.xaxis.categories = cat;
                this.$refs.chart3.refresh();
            }

            if (resp.message.sell_type_chart) {
                let cat = [];
                let serie1 = { name: "Novas vendas", data: [] };
                let serie2 = { name: "Recorrências", data: [] };

                resp.message.sell_type_chart.forEach(i => {
                    cat.push(i._id);
                    serie1.data.push(i.new);
                    serie2.data.push(i.old);
                });

                this.series4 = [serie1, serie2];
                this.chart4Options.xaxis.categories = cat;
                this.$refs.chart4.refresh();
            }

            if (resp.message.sell_income_chart) {
                let cat = [];
                let serie1 = { name: "Novas vendas", data: [] };

                resp.message.sell_income_chart.forEach(i => {
                    serie1.data.push(i.income);
                });

                this.series5 = [serie1];
                this.chart5Options.xaxis.categories = cat;
                this.$refs.chart5.refresh();
            }

            if (resp.message.average_revenue_chart) {
                let cat = [];
                let serie1 = { name: "Receita média 14d", data: [] };

                resp.message.average_revenue_chart.forEach(i => {
                    cat.push(i.reference_date.substring(0, 10));
                    serie1.data.push(i.value);
                });

                this.series6 = [serie1];
                this.chart6Options.xaxis.categories = cat;
                this.$refs.chart6.refresh();
            }
        }

    },

    methods: {
        formatToPrice(value) {
            return `R$ ${value.toFixed(0)}`;
        }
    },
};
</script>

<style scoped>
.icon {
    font-size: 60px;
}

.mx-auto {}

.div-text {
    margin-right: 20px;
}

.card-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>