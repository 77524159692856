<template>
    <div class="menu-page">
        <v-card class="">
            <v-card-title>
                Contatos
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-text-field class="mb-6" v-model="search" append-icon="mdi-magnify" label="Busque por nome ou telefone" clearable outlined single-line hide-details @input="searchOnServer" style="max-width: 500px;"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="primary" class="mr-4" @click="exportFile" :loading="loading">
                        Exportar contatos
                    </v-btn>
                </v-row>

                <v-data-table :headers="headers" :items="objects" :options.sync="options" :server-items-length="totalObjects" :loading="loading">

                    <template v-slot:[`item.created`]="{ item }">
                        {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
                    </template>

                    <template v-slot:[`item.name`]="{ item }">
                        {{ item.override_name || item.name }}
                    </template>

                    <template v-slot:[`item.tags`]="{ item }">
                        <v-chip small class="mr-1 mt-1" v-for="(tag, index_tag) in item.tags" :color="tags_dictionary[tag]?.color" :key="`tag_${index_tag}`" label v-show="tags_dictionary[tag]">{{ tags_dictionary[tag]?.name }}</v-chip>
                    </template>

                    <template v-slot:[`item.ignore`]="{ item }">
                        {{ item.ignore ? 'Sim' : 'Não' }}
                    </template>

                    <template v-slot:[`item.profile_picture`]="{ item }">
                        <v-avatar size="60" class="my-2">
                            <v-img :src="item.profile_picture" v-if="item.profile_picture" />
                            <div v-else class="w-full h-full primary d-flex justify-center">
                                <v-icon color="white" style="font-size:60px">mdi-account</v-icon>
                            </div>
                        </v-avatar>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2" medium color="primary" @click="openContact(item._id)" v-bind="attrs" v-on="on">mdi-account-details-outline</v-icon>
                            </template>
                            <span>Dados do contato</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2" medium color="primary" @click="object = item; tagsDialog = true" v-bind="attrs" v-on="on">mdi-tag-multiple-outline</v-icon>
                            </template>
                            <span>Tags / rótulos</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2" medium @click="object = item; tagsOverrideName = true" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                            </template>
                            <span>Editar nome</span>
                        </v-tooltip>
                        <v-tooltip top v-if="!item.ignore">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium color="error" @click="object = item; dialog1 = true" v-bind="attrs" v-on="on">mdi-account-cancel-outline</v-icon>
                            </template>
                            <span>Ignorar</span>
                        </v-tooltip>
                        <v-tooltip top v-if="item.ignore">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium color="primary" @click="object = item; dialog2 = true" v-bind="attrs" v-on="on">mdi-account-check-outline</v-icon>
                            </template>
                            <span>Deixar de ignorar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="dialog1" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Ignorar contato
                </v-card-title>
                <v-card-text>Tem certeza que deseja ignorar <b>{{ object.name || object.phone }}</b>?</v-card-text>
                <v-card-text>Essa pessoa não terá mais suas mensagens tratadas pelo sistema enquanto estiver com a configuração de ignorar ligada.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog1 = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog1 = false; ignoreContact(true)">
                        Ignorar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="dialog2" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Ignorar contato
                </v-card-title>
                <v-card-text>Tem certeza que deseja deixar de ignorar <b>{{ object.name || object.phone }}</b>?</v-card-text>
                <v-card-text>Essa pessoa passará a ter suas conversas respondidas pelo chatbot e atendimento manual.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog2 = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog2 = false; ignoreContact(false)">
                        Deixar de ignorar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="tagsDialog" max-width="600" persistent>
            <v-card>
                <v-card-title class="text-h5">
                    Atribuir tags
                </v-card-title>
                <v-card-subtitle v-if="object?.name">Organize o atendimento de {{ object.name }}</v-card-subtitle>
                <v-card-text>
                    <v-select chips small-chips multiple outlined v-model="object.tags" :items="tags" item-text="name" item-value="_id" label="Tags ou rótulos" hide-details="auto">
                        <template #selection="{ item }">
                            <v-chip small :color="item.color">{{ item.name }}</v-chip>
                        </template>
                    </v-select>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="tagsDialog = false; getContacts(options);">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" text @click="tagsDialog = false; changeContactTags()">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="tagsOverrideName" max-width="600" persistent>
            <v-card>
                <v-card-title class="text-h5">
                    Editar nome
                </v-card-title>
                <v-card-subtitle>Altere o nome do contato por aqui</v-card-subtitle>
                <v-card-text>
                    <v-text-field class="pt-1" v-model="object.override_name" label="Novo nome do contato" outlined hide-details="auto" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="tagsOverrideName = false; getContacts(options);">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" text @click="tagsOverrideName = false; changeContactName()">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <contact-drawer v-model="contactDrawer" :contact_id="selectedContact" :tags_dictionary="tags_dictionary"></contact-drawer>
    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import { saveAs } from 'file-saver';
import ContactDrawer from "@/components/ContactDrawer";
//import { emitToastr } from "@/lib/Utils";

let timeout = null;


export default {
    name: "ContatosView",

    components: {
        ContactDrawer
    },

    data() {
        return {
            objects: [],
            object: {},
            totalObjects: 0,
            search: "",
            loading: true,
            dialog1: false,
            dialog2: false,
            tagsDialog: false,
            tagsOverrideName: false,
            contactDrawer: false,
            selectedContact: null,
            options: { itemsPerPage: 10 },
            tags_dictionary: {},
            tags: [],
            headers: [
                { text: 'Foto', value: 'profile_picture', sortable: false },
                { text: 'Nome', value: 'name', sortable: false },
                { text: "Telefone", value: "phone", sortable: false },
                { text: "Tags ou rótulos", value: "tags", sortable: false },
                { text: "Ignorar", value: "ignore", sortable: false },
                { text: 'Criação', value: 'created', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    watch: {
        options: {
            handler() {
                this.getContacts(this.options);
            },
            deep: true,
        },
    },

    async mounted() {
        const resp = await Api.getTagsByUser();

        if (!resp.error && resp.message) this.tags = resp.message;
        this.tags.forEach(i => this.tags_dictionary[i._id] = { name: i.name, color: i.color });
    },

    methods: {
        openContact(contact_id) {
            this.selectedContact = contact_id;
            this.contactDrawer = true;
        },

        async getContacts(options) {
            this.loading = true;
            const resp = await Api.getContactsByUser(options);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message.data;
                this.totalObjects = resp.message.total;
            }
        },

        async exportFile() {
            this.loading = true;
            const resp = await Api.exportContacts();
            this.loading = false;
            const filename = `${new Date().toLocaleString().split('/').join('_').split(',').join('').split(':').join('_')}`

            const blob = new Blob([resp], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });

            saveAs(blob, `Contatos_${filename}.xlsx`);
        },

        async ignoreContact(ignore) {
            this.object.ignore = ignore;
            const resp = await Api.ignoreContact(this.object);

            if (!resp.error && resp.message) {
                emitToastr("success", "Contato atualizado com sucesso!")
            }
        },

        async changeContactTags() {
            const resp = await Api.changeContactTags(this.object);
            if (!resp.error) emitToastr("success", "Tags atualizadas");
        },

        async changeContactName() {
            const resp = await Api.changeContactName(this.object);
            if (!resp.error) emitToastr("success", "Nome atualizado");
        },

        async searchOnServer() {

            if (timeout) clearTimeout(timeout);
            this.loading = true;

            timeout = setTimeout(() => {
                this.options.page = 1;
                this.options.query = this.search;
                this.loading = false;
                this.getContacts(this.options);
            }, 1000);
        }
    },
};
</script>

<style scoped></style>